export var marker_popup_frontpage = `
<h4>{{operation.name}}</h4>
<h5>
  <small><i class="fa fa-calendar"></i>&nbsp;{{datetime created_at}}</small> <br>
  {{text}}{{name}} {{# if search_area_id }} [{{search_area.name}}] {{/if}}</h5><br>
{{#if search_area}}
  {{#ifEquals search_area.json.geoJSON.geometry.type "LineString"}}
    <span> <strong>Lengd:</strong> {{toFixed search_area.json.polyline_length 1}} km</span>
  {{^}}
    <span> <strong>Stærð:</strong> {{toFixed search_area.json.area_size 1}} km²</span>
  {{/ifEquals}}
  <br>
{{/if}}
{{#if groups}}
  <br>
  <strong>Hópar:</strong>
  {{#each groups}}
    <br><span>{{name}} [{{tetra}}]</span>
  {{/each}}
  <br>
{{/if}}
<button class='btn btn-primary' onclick="window.location.href = '/operation/{{operation_id}}'">View operation</button>
`