export var _oplog_row = `
{{#unless deleted}}
  <tr class="cursor-pointer {{#ifEquals marked 'Mikilvægt'}}table-danger{{/ifEquals}} {{#ifEquals marked 'Trúnaðarmál'}}table-info{{/ifEquals}} {{#if timeline}}timeline-log{{/if}}" id="log-{{id}}">
    <td class="text-center">
      {{> common_elements/_user_image user=user theme_color=creator_tenant.theme_color }}
      {{#if timeline}}
        <i class="far fa-clock hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.oplog.timeline'}}"></i><br>
      {{/if}}
      {{#if data.device}}
        <i class="far fa-mobile" title="{{i18n 'operation.index.created_from_a_mobile_device'}}"></i><br>
      {{/if}}
      {{#if lat}}
        <i class="far fa-compass hover-show-explanation show_log_on_map" data-html="true" data-toggle="tooltip" title="{{coordinatesIconText decimal_lat decimal_lng current_tenant}}"></i>
      {{/if}}
      {{#if file.url}}
        <a href="{{file.url}}" class="far fa-file"></a><br>
      {{/if}}
      {{#if data.attachments_count}}
        <i class="far fa-paperclip hover-show-explanation" data-toggle="tooltip" title="{{i18n 'application.one_or_more_attachments'}}"></i>
      {{/if}}
      {{#if tooltip}}
        <i class="far fa-map-signs hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.oplog.tooltip'}}"></i>
      {{/if}}
      {{#if task_id}}
        <i class="far fa-exclamation-triangle hover-show-explanation" data-toggle="tooltip" data-placement="right" data-html="true" title="<strong>{{i18n 'application.tasks.task'}}:</strong><br/> {{task.name}}"></i><br>
      {{/if}}
      {{#if data.marker_type_j.marker}}
        <i title="{{data.marker_type_j.name}}" class="hover-show-explanation far fa-{{data.marker_type_j.marker}} show_log_on_map"></i><br>
      {{/if}}
      {{#unlessEquals data.creator_resource_j.tenant_id current_tenant.id}}
        <i class="far fa-handshake" title="{{i18n 'operation.index.created_from_other_tenant'}}"></i>
      {{/unlessEquals}}
      {{#unlessEquals comments_count '0'}}
        <span class="badge badge-info" title="{{i18n 'operation.task.comments'}}"><i class="far fa-comments"></i> {{comments_count}}</span>
      {{/unlessEquals}}
    </td>
    <td onclick="all_logs['{{id}}'].getEditForm();">
      <small style="color:grey">
        {{#if operation}}
          <i class="far fa-home"></i><a href="/operation/{{operation.id}}"> {{operation.name}}</a>
        {{/if}}
        <i class="far fa-calendar"></i> {{date log_time}}
        <i class="far fa-clock"></i> {{formattime log_time}}
        <div class="show-on-fullscreen d-xl-inline-block d-none">
          <i class="far fa-user"></i> {{#if data.creator_resource_j}}[{{data.creator_resource_j.tenant.abbreviation}} - {{data.creator_resource_j.unit.call_sign}}] - {{data.creator_resource_j.name}}{{^}}{{data.tenant_j.abbreviation}} - {{data.user_j.name}}{{/if}}
        </div>
        {{#unlessEquals update_counter '0'}}
          <i class="far fa-edit"></i>
        {{/unlessEquals}}
      </small>
      <div class="row">
        <div class="col-md-12">
          <span class="break-text">{{text}}</span>
        </div>
        {{#if attachment_values}}
          <div class="col-md-12">
            {{#each attachment_values}}
              {{> common_elements/_attachment }}
            {{/each}}
            </div>
        {{/if}}
      </div>
      {{#if url.primary_url_bitly}}
        <a href="{{url.primary_url_bitly}}">{{url.primary_url_bitly}}</a>
      {{^}}
        {{#if url.primary_url}}
        <a style="word-break: break-all;" href="{{url.primary_url}}">{{url.primary_url}}</a>
        {{/if}}
      {{/if}}
      {{#if lat}}
        <span class="badge badge-info">{{lat}}</span>
      {{/if}}
      {{#if lng}}
        <span class="badge badge-info">{{lng}}</span>
      {{/if}}
      {{#if tags}}
        {{#unless display_operation_log_tags}}
          <i class="far fa-tags" title="{{#each tags}}[{{text}}] {{/each}}"/>
        {{/unless}}
      {{/if}}
      {{#each tags}}
        {{> common_elements/_tag display_tags = ../display_operation_log_tags }}
      {{/each}}

      {{#ifEquals marked 'Mikilvægt'}}<i class="far fa-exclamation float-right" title="{{i18n 'operation.oplog.important'}}"></i>{{/ifEquals}} {{#ifEquals marked 'Trúnaðarmál'}}<i class="far fa-lock float-right" title="{{i18n 'operation.oplog.classified'}}"></i>{{/ifEquals}}
    </td>
    <td><p style="display:none">{{tablesorter_time log_time}}</p></td>
  </tr>
{{/unless}}
`;
