export var panel = `
<div class="row">
  <div class="col-md-12">
    <p>{{daily.summary}}</p>
    <div class="tab-content" id="weather-tab-content">
      {{#each hourly}}
          <div role="tabpanel" class="tab-pane {{#if @first}} active{{/if}}" id="weather-tab-{{dt}}">
            <div class="col-md-12 topmargin bottommargin text-center">
              <p><i class="fa fa-clock"></i> {{unixdate dt}} - {{unixtime dt}}</p>
              <p>{{weather.0.description}}</p>
            </div>
            <div class="row">
              <div class="col-md-4 bottommargin text-center">
                <i class="fa fa-thermometer-three-quarters fa-2x"></i><br> {{temp}} °C
              </div>
              <div class="col-md-4 bottommargin text-center">
                <i class="fa fa-windsock fa-2x"></i><br> {{wind_speed}} m/s
              </div>
              <div class="col-md-4 bottommargin text-center">
                <i class="fa fa-raindrops fa-2x"></i><br> {{rain.1h}} mm
              </div>
            </div>
          </div>
      {{/each}}
    </div>
  </div>
  <div class="col-md-12">
    <input type="range" class="custom-range" id="weather-slider" step="3600" value="{{hourly.0.dt}}" min="{{hourly.0.dt}}" max="{{last hourly 'dt'}}"  list="tickmarks">
    <small class="float-left">{{unixdate hourly.0.dt}} - {{unixtime hourly.0.dt}}</small>
    <small class="float-right">+48 klst</small>
    <datalist id="tickmarks">
      {{#each hourly}}
        <option value="{{time}}">{{time}}</option>
      {{/each}}
    </datalist>
  </div>
  <div class="col-md-4 topmargin">
    <label>Lat</label>
    <input class="form-control" type="text" id="weather-lat" value="{{lat}}" placeholder="lat">
  </div>
  <div class="col-md-4 topmargin">
    <label>Lng</label>
    <input class="form-control" type="text" id="weather-lng" value="{{lon}}" placeholder="lng">
  </div>
  <div class="col-md-4 topmargin">
    <label>Fetch</label>
    <button type="button" id="updateWeatherForecast" class="btn btn-primary btn-block">Update</button>
  </div>
  <div class="col-md-12 text-center topmargin">
    <small><i class="fa fa-clock"></i> {{timesinceunix hourly.0.dt}}</small>
  </div>
</div>
`;
