export var _message = `
{{#if show_datestamp}}
  <div class="row mt-2 text-center">
    <div class="col"><small class="text-muted text-uppercase">{{formatdate created_at}}</small></div>
  </div>
{{/if}}
{{#if show_timestamp}}
  <div class="row mt-2 text-center">
    <div class="col"><small class="text-muted">{{formattime created_at}}</small></div>
  </div>
{{/if}}
<div class="row mt-2">
  <div class="col">
    {{#unlessEquals _current_user.id user_id }}<small>{{user.name}}</small>{{/unlessEquals}}<br>
    {{#if include_image}}
      <div class="card {{#ifEquals _current_user.id user_id}}float-right{{/ifEquals}}" style="width: 18rem;">
        <a href="{{include_image.path}}" target="_blank">
          <img src="{{include_image.small}}" class="card-img-top" alt="{{include_image.name}}"/>
        </a>
      </div>
    {{else}}
      <span title="{{datetime created_at}}" class="text-left chat-bubble badge {{#ifEquals _current_user.id user_id}}badge-primary float-right{{^}}badge-medium-light{{/ifEquals}}">
        {{#if m_text}}
          <div class="message_content" style="white-space:pre-line">{{m_text}}</div>
        {{/if}}
        {{#if location}}
          <a style="{{#ifEquals _current_user.id user_id}}color: white;{{^}}color: #212529;{{/ifEquals}}" href="/map?latitude={{location.latitude}}&longitude={{location.longitude}}&marker=true" target="_blank">
            <span class="message_content"><i class="fa fa-map-marker fa-fw"/> Lat: {{location.latitude}}, Lng: {{location.longitude}}</span>
          </a>
        {{/if}}
      </span>
    {{/if}}
  </div>
</div>
`;
