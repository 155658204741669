export var _priority_row = `
<tr style="cursor: pointer" id="priority-{{id}}">
  <td class="priority-rank" onclick="get_edit_form_for_priority('{{id}}')">{{rank}}</td>
  <td onclick="get_edit_form_for_priority('{{id}}')">{{name}}</td>
  <td onclick="get_edit_form_for_priority('{{id}}')">{{#if disabled}} {{i18n 'application.admin.inactive'}} {{^}} {{i18n 'application.admin.active'}} {{/if}}</td>
  <td onclick="get_edit_form_for_priority('{{id}}')">{{#if default}}<i class="fa fa-check"></i>{{/if}}</td>
  <td onclick="get_edit_form_for_priority('{{id}}')">{{#if operation_priority}}<i class="fa fa-check"></i>{{/if}}</td>
  <td onclick="get_edit_form_for_priority('{{id}}')">{{#if task_priority}}<i class="fa fa-check"></i>{{/if}}</td>
  <td>
    <i class="fa fa-arrow-up" onclick="updatePriorityPosition('{{id}}', -1)"></i>
    <i class="fa fa-arrow-down" onclick="updatePriorityPosition('{{id}}', 1)"></i>
  </td>
</tr>
`
