export var sharebuttons = `
<div id="share_dropdown" title="{{i18n 'helpers.share'}}" class="dropdown {{#if dropup}}dropup{{/if}}">
  <button class="btn btn-secondary btn-block" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="fa fa-share"></i>
    <i class="fa fa-caret-down"></i>
  </button>
  <ul class="dropdown-menu {{#if dropdown-menu-right}}dropdown-menu-right{{/if}}" aria-labelledby="dLabel">
    <a class="dropdown-item" href="#" id="email-button" onclick="send_item_as('{{item_type}}', '{{id}}', 'email')"><i class="fa fa-fw fa-envelope"></i> Email</a>
    <a class="dropdown-item" href="#" id="sms-button" onclick="send_item_as('{{item_type}}', '{{id}}', 'sms')"><i class="fa fa-fw fa-sms"></i> SMS</a>
    <a class="dropdown-item" href="#" id="message-button" onclick="send_item_as('{{item_type}}', '{{id}}', 'message')"><i class="fa fa-fw fa-bullhorn"></i>{{i18n "application.admin.message"}}</a>
    {{#ifEquals item_type 'task'}}
      <a class="dropdown-item" href="/tasks/{{id}}/export_to_calendar.ics"><i class="fa fa-fw fa-calendar"></i>{{i18n "operation.task.calendar"}}</a>
    {{/ifEquals}}
  </ul>
</div>
<style>
  .popover {
    width: 400px !important;
    max-width: 400px !important;
    z-index: 100 !important;
  }
</style>
<script type="text/javascript">
    function formatRepo(resource) {
        if (resource.loading) return resource.text;

        function peopleOrMachine(resource) {
          if (resource) {
            let logo_url =  resource.tenant && resource.tenant.include_logo ? resource.tenant.include_logo.small || resource.tenant.include_logo.path : "";
            let icon_url =  resource.unit && resource.unit.include_unit_logo ?  resource.unit.include_unit_logo.small || resource.unit.include_unit_logo.path : logo_url;
            return "class=' groups-avatar' style='background-image:url("+icon_url+")'";
          }
        }
        // getting rid of any strange data
        if (resource.name === undefined) {
            return;
        }
        //image and name
        var markup = '<div ' + peopleOrMachine(resource) + '></div><h6 class="nomargin">' + resource.name;
        // dont display meta info if vehicle
        if (resource.type === "Device") {
            markup += '<small>  ' + resource.device_info.device_type + '</small></h6>';
        }
        markup += '</h5><div class="row groups-meta small"><span class="groups-meta-icon fa fa-shield"></span>' + resource.unit.name+ '</div>';

        return markup;
    }

    function formatGroupSelection(resource) {
        // add_name_to_select_list(resource);
        return resource.name || resource.unit;
    }
    function initSelectMenu() {
      let placeholder = I18n.t('application.operation_search_history.user');
      $("select.share_user_id_select").select2({
          language: "is",
          theme: "bootstrap4",
          multiple: false,
          placeholder: placeholder,
          allowClear: true,
          ajax: {
              url: "/resources_json.json",
              dataType: 'json',
              delay: 250,
              data: function(params) {
                  var area_id = 0
                  var group_id = 0;
                  var only_users = true;
                  return {
                      group_id,
                      area_id,
                      only_users,
                      q: params.term, // search term / user input
                  };
              },
              processResults: function(data, params) {
                  return {
                      results: data,
                  };
              },
              cache: true
          },
          minimumInputLength: 2,
          templateResult: formatRepo,
          templateSelection: formatGroupSelection,
          escapeMarkup: function(markup) {
              return markup;
          }
      })
    };
    function showConfirmationMessage(response) {
      if (response) {
          swal({
              title: I18n.t("operation.share.success"),
              text:  I18n.t("operation.share.message_sent"),
              type: "success"
          })
      } else {
          swal({
              title:  I18n.t("operation.share.error"),
              text:  I18n.t("operation.share.message_not_sent"),
              type: "warning"
          })
      }
    }
    $(document).on("click", ".close_popover", function(event) {
        $("#share_buttons_"+"{{item_type}}").popover("hide")
        $("#share_buttons_"+"{{item_type}}").popover("destroy")
    })
    // $(".share_buttons").on("click", "#share_dropdown", function(event) {
    //     $("#share_buttons_{{item_type}}").popover("destroy")
    // })

    function send_item_as(item_type, id, message_type) {
        $("#share_buttons_"+item_type).popover({
            html: true,
            placement: 'top',
            title: I18n.t("operation.share.send_" + item_type + "_as_" + message_type) + "<i class='fa fa-times float-right cursor-pointer close_popover'/>",
            content: "<div id='share_buttons_" + item_type.toString() + "_" + id.toString() + "'></div>"
        }).popover("show");
        $("#share_buttons_" + item_type.toString() + "_" + id.toString()).html("<select style='width: 300px' class='share_user_id_select'></select><button type='button' message_type='" + message_type + "' id='send_item_confirm' class='topmargin btn btn-success btn-block'>" + I18n.t("application.form.confirm") + "</button>")
        initSelectMenu();
    }

    $(document).on("click", "#send_item_confirm", function(event) {
        var user_id = $(".share_user_id_select").val()
        var item_id = '{{id}}';
        var item_type = "{{item_type}}"
        var message_type = $(event.target).attr("message_type");
        $("#send_item_confirm").html("<i class='fa fa-spin fa-spinner'/>").attr({disabled: true})
        create_request(user_id, item_id, item_type, message_type).then(data => data.json()).then(function(response) {
            $("#share_buttons_" + item_type).popover("hide")
            showConfirmationMessage(response.success);
            $("#share_buttons_" + item_type).popover("destroy")
        }).catch(function(error) {
            console.error(error);
        });
    })

    function create_request(user, item_id, item_type, message_type) {
        var url = "/operation/send_item_as?";
        var operation_id = '{{op_id}}';
        url += $.param({
            user,
            operation_id,
            item_id,
            item_type,
            message_type
        })
        var request = new Request(url, {
            method: 'POST',
            headers: requestHeaders,
            mode: 'cors',
            dataType: 'json',
            cache: 'default',
            credentials: 'include'
        });
        return fetch(request);
    };

</script>
`;
