export var device_list = `
{{#devices}}
  <tr>
    <td><b>{{name}}</b>{{#if custodian}}<i class="far fa-user hover-show-explanation" title="[{{custodian.unit.call_sign}}] {{custodian.name}} - {{custodian.phone_number}}"/>{{/if}}</td>
    <td>
      <div class="btn-group dropright">
        <button type="button" class="btn btn-secondary btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="background-color:{{resource_status.background_color}}; color:{{resource_status.text_color}}; width:200px;">{{resource_status.name}} <small>[{{resource_status.tenant.abbreviation}}]</small> <span class="caret"></span>
        </button>
        <div class="dropdown-menu">
          {{#each ../resource_statuses}}
            <a href="#" class="dropdown-header">{{this.1.tenant.name}}</a>
            {{#each this}}
              <a class="dropdown-item" href="#" onClick="updateResourceStatus('{{../../id}}', '{{this.id}}')"><i class="fas fa-circle" style="height: 14px; color:{{this.background_color}};"></i> {{this.name}}</a>
            {{/each}}
            <div class="dropdown-divider"></div>
          {{/each}}
        </div>
      </div>
    </td>
    <td>
      {{#if resource_status_updated_at}}
        {{datetime resource_status_updated_at}}
      {{/if}}
    </td>
    <td>
      <span class="{{#unless hide_edit}}d-none{{/unless}}">{{info_comment}}</span>
      <div class="form-group {{#if hide_edit}}d-none{{/if}}">
        <textarea type="text" class="form-control device_info_comment" name="" id="{{id}}"  rows="2" style="width: 300px" >{{info_comment}}</textarea>
      </div>
    </td>
    {{#each ../custom_fields}}
      {{#if on_information_view}}
        <td>{{get_custom_value ../custom_values this}}</td>
      {{/if}}
    {{/each}}
    <td>{{tenant.name}}</td>
    <td>
      {{#ifEquals ../tenant.id tenant_id }}
        <a href="/devices/{{id}}">{{i18n 'helpers.show'}}</a> |
        <a href="/devices/{{id}}/edit">{{i18n 'helpers.edit'}}</a>
      {{/ifEquals}}
    </td>
  </tr>
{{/devices}}
`;
