export var _sst_event_row = `
<tr class="sst-event-row cursor-pointer {{#ifEventCreatedInOp tasks operation_id}}active {{#if hideActive}}d-none{{/if}}{{^}}{{/ifEventCreatedInOp}}" id="{{id}}">
  <td>
    {{#if gps_lat}}
      <i class="fa fa-compass hover-show-explanation" onclick="showSSTTaskOnMap('{{gps_lat}}', '{{gps_lon}}')" lat="{{gps_lat}}" lng="{{gps_lon}}" title="LAT: {{gps_lat}} LNG: {{gps_lon}}"></i>
    {{/if}}
  </td>
  <td href="#mySstModal" data-toggle="modal">
    <small style="color:grey">
      <p class="d-none">{{unix created_at}}</p>
      <i class="fa fa-calendar"></i> {{date created_at}}
      <i class="fa fa-clock"></i> {{formattime created_at}}
      <i class="fa fa-home"></i> {{address}} {{#if postal_code}}[{{postal_code}}]{{/if}}
    </small>
    <br>

    {{extra}}
    {{#sst_logs}}
      {{text}}&nbsp;
    {{/sst_logs}}
  </td>
  <td href="#mySstModal" data-toggle="modal">{{postal_code}}</td>
  <td href="#mySstModal" data-toggle="modal"><small>F{{priority}}</small></td>
  <td>
    {{createSSTTaskButton tasks operation_id}}
  </td>
</tr>
`;
