export var group_task_list_row = `
<tr id="group-task-{{id}}">
  <td>{{name}} {{#if search_area}} - {{search_area.name}}{{/if}}</td>
  <td>

      {{#unless done}}<span id="group-task-finish-{{id}}" class="cursor-pointer badge badge-success groups-task-finish" name="{{id}}" onclick="GroupJs.updateGroupTaskStatusToDone('{{id}}')">Ljúka</span>{{/unless}}

        <i class="fa fa-trash float-right" name="{{id}}" onclick="GroupJs.removeTaskFromGroup(this)"></i>


  </td>
</tr>
`;
