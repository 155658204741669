export var _attachment = `
<a target="_blank" href="{{path}}" onclick="event.stopImmediatePropagation();" title="{{name}}" class="mr-2 float-left">
  {{#if small}}
        <img src="{{small}}" alt="{{name}}" style="width:100%;" class="mt-1 img-thumbnail"/>
  {{^}}
    <label class="cursor-pointer badge badge-primary">
      <span class="text-truncate d-inline-block" style="max-width: 350px;">
        <i class="fa {{filetypeicon content_type}}"></i>
        {{name}}
      </span>
    </label>
  {{/if}}
</a>
`;
