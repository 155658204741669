export var info = `
<div class="card">
  <div class="card-header" id="tasks-table-heading">
      <i class="fa fa-exclamation-triangle"></i> <span class="float-right">{{operation.name}}</span>
  </div>
  <div class="card-body" id="task-card-body">
    <div class="col-md-4">
      <h3>{{priority}}</h3>
      <p>{{name}}</p>
    </div>
    <div class="col-md-4">
      {{#each groups }}
        <h3>{{name}}</h3>
        <table class="table">
          <tbody>
            {{#each resources }}
              <tr>
                <td> {{#if call_id}}{{call_id}} - {{/if}} {{name}}</td>
                <td>{{phone_number}}</td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      {{/each}}
    </div>
    <div class="col-md-2"></div>
    <div class="col-md-2">
      <h3><i class="fa fa-map-marker"></i></h3>
      <table class="table">
        <tbody>
          {{# each data.search_area_j.json.geoJSON.geometry.coordinates }}
            <tr>
              <td>
                 {{parseWSG84 lat}}
              </td>
              <td>
                 {{parseWSG84 lng}}
              </td>
            </tr>
          {{/each}}

        </tbody>
      </table>
    </div>

  </div>
</div>
`