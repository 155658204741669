export var tracker_list = `
{{#resources}}
<div class="card bottommargin resource-card cursor-pointer" resource_id="{{resource.id}}" title="Latitude: {{formatDecimalCoords location.latitude}}, Longitude: {{formatDecimalCoords location.longitude}}">
  <div class="card-body">
    <b class="card-text">
      <div class="tracker-color-dot" style="background-color: {{color}}"/>[{{resource.unit.call_sign}}] {{resource.name}}
    </b>
    <div class="row">
      <div class="col"><p class="card-text"><i class="fa fa-tachometer-fast fa-fw"></i> {{ms_to_kmh location.speed}} km/h</p></div>
      <div class="col"><p class="card-text"><i class="fa fa-arrow-from-bottom fa-fw"></i> {{toFixed location.altitude 2}} m</p></div>
    </div>
    <div class="row text-muted">
      <div class="col"><i class="fa fa-clock fa-fw"></i><small>{{timesince location.timestamp}}</small></div>
    </div>
    <div class="row">
      {{#unlessEquals distanceTraveled '0'}}
        <div class="col"><p class="card-text" title="Tracked distance traveled within selected time period"><i class="fa fa-map-signs fa-fw"></i> {{metersOrKm distanceTraveled}}</p></div>
      {{/unlessEquals}}
      <div class="col">
        <div class="checkbox float-right">
          <input type="checkbox" class="plot_resource_path" {{#if checked}}checked{{/if}} resource_id={{resource.id}}>
          Draw Path
        </div>
      </div>
    </div>
  </div>
</div>
{{/resources}}
`;
