export var watches_list = `
<div class="row mt-3">
  <div class="col-md-12">
    {{#watches}}
      <h5>{{name}}</h5>
      {{#each resource_watches}}
          {{#if on_call}}
            <h6 style="margin-left:20px; color:#555;">{{resource.name}} - {{resource.phone_number}}</h6>
          {{/if}}
          {{#if on_remote_call}}
            <h6 style="margin-left:20px; color:#555;"><i class="fa fa-phone"/> {{resource.name}} - {{resource.phone_number}}</h6>
          {{/if}}
        </span>
      {{/each}}
      <br>
    {{/watches}}
  </div>
</div>
`;
