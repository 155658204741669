export var _task_row = `
{{#unless deleted}}
{{#unless subheading_task}}
<tr id="task-{{id}}" class="task-row cursor-pointer {{#if search_area_id}} search_area_task {{/if}} {{#if done}} done {{/if}}{{#if has_groups}}working{{^}}unassigned{{/if}} ">
    <td>
      {{#if file.url}}
        <a href="{{file.url}}" class="far fa-file"></a>
      {{/if}}
      {{#if data.attachments_count}}
        <i class="far fa-paperclip hover-show-explanation" data-toggle="tooltip" title="{{i18n 'application.one_or_more_attachments'}}"></i>
      {{/if}}
      {{#if data.device}}
        <i class="far fa-mobile" title="{{i18n 'operation.index.created_from_a_mobile_device'}}"></i><br>
      {{/if}}
      {{#if operation_logs}}
        <i class="far fa-map-marker  hover-show-explanation" title="{{i18n 'application.one_or_more_logs'}}"></i><br>
      {{/if}}
      {{#if decimal_lat}}
        <i class="far fa-compass show_task_on_map hover-show-explanation" data-html="true" title="{{coordinatesIconText decimal_lat decimal_lng current_tenant}}"></i>
      {{/if}}
      {{#if is_control_measure}}
        <i class="far fa-hand-point-right hover-show-explanation" title="Mótvægisaðgerð"></i>
      {{/if}}
      {{#if blocking_tasks}}
        <span class="badge badge-danger"><i class="far fa-tasks hover-show-explanation" title="Has Blocking Tasks"></i> {{count_not_done blocking_tasks}}</span>
        </br>
      {{/if}}
      {{#if dependent_tasks}}
        <span class="badge badge-success"><i class="far fa-project-diagram hover-show-explanation" title="Has Dependent Tasks"></i> {{count_not_done dependent_tasks}}</span>
        </br>
      {{/if}}
      {{#if data.marker_type_j}}
        </br>
        <i title="{{data.marker_type_j.name}}" class="fa fa-{{data.marker_type_j.marker}} marker-stroke icon-task-{{#if done}}done{{^}}{{#if data.groups_j}}active{{^}}unassigned{{/if}}{{/if}}"></i>
      {{/if}}
      {{#unlessEquals data.creator_resource_j.tenant_id current_tenant.id}}
        <i class="far fa-handshake" title="{{i18n 'operation.index.created_from_other_tenant'}}"></i>
      {{/unlessEquals}}
      {{#unlessEquals comments_count '0'}}
        <span class="toggle_task_comments badge badge-info"title="{{i18n 'operation.task.comments'}}"><i class="far fa-comments"></i> {{comments_count}}</span>
      {{/unlessEquals}}
    </td>
    <td onclick="all_tasks['{{id}}'].getEditForm();" colspan="2">
      <small style="color:grey">
        {{#if operation}}
          <i class="far fa-home"></i><a href="/operation/{{operation.id}}"> {{operation.name}}</a>
        {{/if}}
        <i class="far fa-calendar"></i> {{date created_at}}
        <i class="far fa-clock"></i> {{formattime created_at}}
        <div class="show-on-fullscreen d-none">
          {{#if data.creator_resource_j}}
            <i class="far fa-user" title="[{{data.creator_resource_j.tenant.abbreviation}} - {{data.creator_resource_j.unit.call_sign}}] - {{data.creator_resource_j.name}}"></i> <span class="d-xl-inline-block">[{{data.creator_resource_j.tenant.abbreviation}} - {{data.creator_resource_j.unit.call_sign}}] - {{data.creator_resource_j.name}}</span>
          {{^}}
            <i class="far fa-user" title="{{data.tenant_j.abbreviation}} - {{data.user_j.name}}"></i> <span class="d-xl-inline-block">{{data.tenant_j.abbreviation}} - {{data.user_j.name}}</span>
          {{/if}}
        </div>
      </small>
      <div class="row">
        <div class="col-md-12">
          {{#if checklist}}
            <span class='badge badge-info' title="{{i18n 'operation.task.checklist'}}"><i class="far fa-check-circle"></i>&nbsp;{{checklist.name}}</span> -
          {{/if}}
          <div>
            {{#if subheading_task}}
              <span class="break-text"><b>{{name}}</b></span>
            {{^}}
              {{#if data.search_area_j}}<span class="badge badge-success mr-2">{{i18n 'operation.task.search_area'}} {{data.search_area_j.name}}</span>{{/if}}<span class="break-text">{{name}}</span>
            {{/if}}
            {{address}}
          </div>
          {{#if data.search_area_j}}
            {{#ifEquals data.search_area_j.json.geoJSON.geometry.type "LineString"}}
              <small> {{i18n 'operation.task.length'}}: {{toFixed data.search_area_j.json.polyline_length 3}} km</small>
            {{^}}
              <small> {{i18n 'operation.task.size'}}: {{toFixed data.search_area_j.area_size 3}} km² | {{i18n 'operation.task.circumference'}}: {{toFixed data.search_area_j.json.polyline_length 3}} km</small>
            {{/ifEquals}}
            {{^}}
              {{#if lat}}
                <span class="badge badge-info">{{lat}}</span>
              {{/if}}
              {{#if lng}}
                <span class="badge badge-info">{{lng}}</span>
              {{/if}}
          {{/if}}
          {{#if tags}}
            {{#unless display_task_tags}}
              <i class="far fa-tags" title="{{#each tags}}[{{text}}] {{/each}}"/>
            {{/unless}}
          {{/if}}

          {{#each tags}}
            {{> common_elements/_tag display_tags = ../display_task_tags }}
          {{~/each}}

          {{#if is_control_measure}}
            <span class='badge badge-success'><small>{{i18n 'operation.task.counter_measure'}}</small></span>
          {{/if}}
          {{#if due_date}}
            {{#unless done}}
              <span class='badge {{duedateclass due_date}}'>{{i18n 'operation.task.due_date'}}: {{timesince due_date}}</span>
              <br/>
            {{/unless}}
          {{/if}}
          {{#if attachment_values}}
            <div class="col-md-12">
              {{#each attachment_values}}
                {{> common_elements/_attachment }}
              {{/each}}
              </div>
          {{/if}}
          {{#if display_task_comments}}
              {{#each comments}}
                {{> common_elements/_comment}}
              {{~/each}}
          {{/if}}

        </div>
      </div>
    </td>
    <td onclick="all_tasks['{{id}}'].getEditForm();">
      <p style="display:none">{{tablesorter_time created_at}}</p>
    </td>
    <td class="cursor-pointer">
      <button class="{{#isAddToGroupButtonVisible}}d-none{{/isAddToGroupButtonVisible}} btn btn-sm btn-primary mr-3 group-add-button" onclick="GroupJs.addTaskButtonClick(this)"><i class="far fa-plus"></i></button>
      {{#if done}}
        <span style="display:none">3</span>
        <i data-toggle="dropdown" class="far fa-check-square hover-show-explanation" title="{{i18n 'operation.task.finished_at'}}: {{datetime solved_at}}" ></i> {{solved}}x
      {{^}}
        {{#if has_groups}}
            <span style="display:none">2</span>
            <i data-toggle="dropdown" title="{{i18n 'operation.task.assigned'}}" class="dropdown-toggle far fa-caret-square-right"></i>
        {{^}}
            <span style="display:none">1</span>
            <i data-toggle="dropdown" title="{{i18n 'operation.task.unassigned'}}" class="dropdown-toggle far fa-square"></i>
        {{/if}}
      {{/if}}
      <div class="dropdown-menu dropdown-menu-right">
        {{#if done}}
          <a class="dropdown-item" onclick="all_tasks['{{id}}'].updateStatus({done:false, active: true});" href="#"><i class="far fa-fw fa-sync"></i>{{i18n 'operation.task.restart'}}</a>
        {{^}}
          <a class="dropdown-item" onclick="all_tasks['{{id}}'].updateStatus({done:true, active: false});" href="#"><i class="far fa-fw fa-check-square"></i>{{i18n 'operation.task.finish'}}</a>
        {{/if}}
          <a class="dropdown-item" onclick="all_tasks['{{id}}'].getEditForm();" href="#"><i class="far fa-fw fa-edit"></i>{{i18n 'helpers.edit'}}</a>
          <div class="dropdown-header" style="white-space: normal; max-width: 600px;">{{#if data.search_area_j}}#{{data.search_area_j.name}} - {{/if}} {{name}}</div>
      </div>
    </td>
    <td class="task_priority" id="{{id}}" title="{{i18n 'operation.task.priority'}}" style="display:flex;text-align: right;">
        <small id="task-priority-{{id}}">{{priority.name}}</small>&nbsp;
        <a onclick="all_tasks['{{id}}'].updatePriority('up')" class="far fa-fw fa-arrow-up" id="priority-up-{{id}}"></a>
        <a onclick="all_tasks['{{id}}'].updatePriority('down')" class="far fa-arrow-down float-right" id="priority-down-{{id}}"></a>
    </td>
  </tr>
  {{/unless}}
  {{/unless}}
`;
