export var subfields = `
<div class="form-group">
  <div class="input-group">
      <input type="text" class="form-control" name="custom_field[sub_fields][{{#if id}}{{id}}{{^}}{{random}}{{/if}}][label]" value="{{label}}">
      <div class="input-group-btn">
        <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="subfield_kind_select_title" id="{{#if id}}{{id}}{{^}}{{random}}{{/if}}">{{capitalize subfield_kind}}</span> <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-right">
          <a href="#" class="dropdown-item changeSubFieldKind {{#ifEquals subfield_kind "option"}}active{{/ifEquals}}" data-kind="option" data-id="{{#if id}}{{id}}{{^}}{{random}}{{/if}}" data-target="custom_field[sub_fields][{{#if id}}{{id}}{{^}}{{random}}{{/if}}][subfield_kind]" >Option</a>
          <a href="#" class="dropdown-item changeSubFieldKind {{#ifEquals subfield_kind "title"}}active{{/ifEquals}}" data-kind="title" data-id="{{#if id}}{{id}}{{^}}{{random}}{{/if}}" data-target="custom_field[sub_fields][{{#if id}}{{id}}{{^}}{{random}}{{/if}}][subfield_kind]" >Title</a>
        </ul>
      </div><!-- /btn-group -->
      <input type="number" class="form-control" name="custom_field[sub_fields][{{#if id}}{{id}}{{^}}{{random}}{{/if}}][subfield_rank]" value="{{subfield_rank}}">

  </div>
  <div class="checkbox">
    <label>
      <input type="checkbox" name="custom_field[sub_fields][{{#if id}}{{id}}{{^}}{{random}}{{/if}}][disabled]" {{#if disabled}}checked{{/if}} value="1"> Disabled
    </label>


  </div>


  <input type="hidden" name="custom_field[sub_fields][{{#if id}}{{id}}{{^}}{{random}}{{/if}}][subfield_kind]" value="{{subfield_kind}}">
</div>
`;
