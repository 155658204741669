export var watch_list = `
{{#watches}}
  <tr>
    <td>{{name}}</td>
    <td>
      {{#each resource_watches}}
        {{#if on_call}}
          {{#if on_remote_call}}
            (<i class="fa fa-phone"></i>)
          {{/if}}
          {{resource.name}}
           - <i class="fa fa-phone"></i> <a href="tel:{{resource.phone_number}}">{{resource.phone_number}}</a>
          </br>
        {{/if}}
      {{/each}}
    <td>
  </tr>
{{/watches}}
`;
