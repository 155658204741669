export var _group_row = `
<tr title="Created At: {{datetime created_at}}" id="group-{{id}}" class="group-row cursor-pointer">
   <td>
        {{#if file.url}}
          <a href="{{file.url}}"><i class="far fa-file"></i> </a>
        {{/if}}
        {{#if extra}}
          <i class="far fa-users hover-show-explanation" data-toggle="tooltip" title="{{i18n 'operation.group.extras_in_group'}}: {{extra}}"></i>
        {{/if}}
        {{#if data.device}}
          <i class="far fa-mobile" title="{{i18n 'operation.index.created_from_a_mobile_device'}}"></i><br>
        {{/if}}
        {{#if data.attachments_count}}
          <i class="far fa-paperclip hover-show-explanation" data-toggle="tooltip" title="{{i18n 'application.one_or_more_attachments'}}"></i>
        {{/if}}
        {{#unlessEquals data.creator_resource_j.tenant_id current_tenant.id}}
          <i class="far fa-handshake" title="{{i18n 'operation.index.created_from_other_tenant'}}"></i>
        {{/unlessEquals}}
   </td>
   <td>
        {{name}} {{#if tetra}} [{{tetra}}] {{/if}}
        {{#if tags}}
          {{#unless display_group_tags}}
            <i class="far fa-tags" title="{{#each tags}}[{{text}}] {{/each}}"/>
          {{/unless}}
        {{/if}}
        {{#each tags}}
          {{> common_elements/_tag display_tags = ../display_group_tags }}
        {{~/each}}
   </td>
   <td>
      {{number}}
   </td>
   <td>
         {{#if operational}}
             {{#if has_tasks}}
                 <p style="display:none">2</p><i class="fa fa-play" title="{{i18n 'operation.group.active'}}"></i>
             {{^}}
                 {{#if can_have_tasks}}
                     <p style="display:none">1</p><i class="far fa-square" title="{{i18n 'operation.group.can_have_tasks'}}"></i>
                 {{^}}
                     <p style="display:none">3</p><i class="fa fa-pause" title="{{i18n 'operation.group.resting'}}"></i>
                 {{/if}}
             {{/if}}
         {{^}}
             <p style="display:none">4</p><i class="fa fa-stop" title="{{i18n 'operation.group.quit'}}"></i>
         {{/if}}
   </td>
 </tr>
`;
