//Handlebars

import Handlebars from "handlebars";
var HandlebarsTemplates = {};
window.Handlebars = Handlebars;
window.HandlebarsTemplates = HandlebarsTemplates;

import { _op_row } from "templates/operations/_op_row";
var compiled = Handlebars.compile(_op_row);
Handlebars.registerPartial("operations/_op_row", compiled);
HandlebarsTemplates["operations/_op_row"] = compiled;

import { op_list } from "templates/operations/op_list";
var compiled = Handlebars.compile(op_list);
Handlebars.registerPartial("operations/op_list", compiled);
HandlebarsTemplates["operations/op_list"] = compiled;

import { subfields } from "templates/custom_fields/subfields";
var compiled = Handlebars.compile(subfields);
Handlebars.registerPartial("custom_fields/subfields", compiled);
HandlebarsTemplates["custom_fields/subfields"] = compiled;

import { device_list } from "templates/information_views/device_list";
var compiled = Handlebars.compile(device_list);
Handlebars.registerPartial("information_views/device_list", compiled);
HandlebarsTemplates["information_views/device_list"] = compiled;

import { watch_list } from "templates/information_views/watch_list";
var compiled = Handlebars.compile(watch_list);
Handlebars.registerPartial("information_views/watch_list", compiled);
HandlebarsTemplates["information_views/watch_list"] = compiled;

import { _message } from "templates/messages/_message";
var compiled = Handlebars.compile(_message);
Handlebars.registerPartial("messages/_message", compiled);
HandlebarsTemplates["messages/_message"] = compiled;

import { message_list } from "templates/messages/message_list";
var compiled = Handlebars.compile(message_list);
Handlebars.registerPartial("messages/message_list", compiled);
HandlebarsTemplates["messages/message_list"] = compiled;

import { tracker_list } from "templates/trackers/tracker_list";
var compiled = Handlebars.compile(tracker_list);
Handlebars.registerPartial("trackers/tracker_list", compiled);
HandlebarsTemplates["trackers/tracker_list"] = compiled;

import { _sst_event_row } from "templates/sst_events/_sst_event_row";
var compiled = Handlebars.compile(_sst_event_row);
Handlebars.registerPartial("sst_events/_sst_event_row", compiled);
HandlebarsTemplates["sst_events/_sst_event_row"] = compiled;

import { sst_event_list } from "templates/sst_events/sst_event_list";
var compiled = Handlebars.compile(sst_event_list);
Handlebars.registerPartial("sst_events/sst_event_list", compiled);
HandlebarsTemplates["sst_events/sst_event_list"] = compiled;

import { single_op_row } from "templates/operations/single_op_row";
var compiled = Handlebars.compile(single_op_row);
Handlebars.registerPartial("operations/single_op_row", compiled);
HandlebarsTemplates["operations/single_op_row"] = compiled;

import { panel } from "templates/weather/panel";
var compiled = Handlebars.compile(panel);
Handlebars.registerPartial("weather/panel", compiled);
HandlebarsTemplates["weather/panel"] = compiled;

import { group_list } from "templates/groups/group_list";
var compiled = Handlebars.compile(group_list);
Handlebars.registerPartial("groups/group_list", compiled);
HandlebarsTemplates["groups/group_list"] = compiled;

import { _group_row } from "templates/groups/_group_row";
var compiled = Handlebars.compile(_group_row);
Handlebars.registerPartial("groups/_group_row", compiled);
HandlebarsTemplates["groups/_group_row"] = compiled;

import { group_task_list_row } from "templates/groups/group_task_list_row";
var compiled = Handlebars.compile(group_task_list_row);
Handlebars.registerPartial("groups/group_task_list_row", compiled);
HandlebarsTemplates["groups/group_task_list_row"] = compiled;

import { single_group_row } from "templates/groups/single_group_row";
var compiled = Handlebars.compile(single_group_row);
Handlebars.registerPartial("groups/single_group_row", compiled);
HandlebarsTemplates["groups/single_group_row"] = compiled;

import { status_dot } from "templates/groups/status_dot";
var compiled = Handlebars.compile(status_dot);
Handlebars.registerPartial("groups/status_dot", compiled);
HandlebarsTemplates["groups/status_dot"] = compiled;

import { marker_popup } from "templates/map/marker_popup";
var compiled = Handlebars.compile(marker_popup);
Handlebars.registerPartial("map/marker_popup", compiled);
HandlebarsTemplates["map/marker_popup"] = compiled;

import { marker_popup_frontpage } from "templates/map/marker_popup_frontpage";
var compiled = Handlebars.compile(marker_popup_frontpage);
Handlebars.registerPartial("map/marker_popup_frontpage", compiled);
HandlebarsTemplates["map/marker_popup_frontpage"] = compiled;

import { oplog_list } from "templates/operation_logs/oplog_list";
var compiled = Handlebars.compile(oplog_list);
Handlebars.registerPartial("operation_logs/oplog_list", compiled);
HandlebarsTemplates["operation_logs/oplog_list"] = compiled;

import { _oplog_row } from "templates/operation_logs/_oplog_row";
var compiled = Handlebars.compile(_oplog_row);
Handlebars.registerPartial("operation_logs/_oplog_row", compiled);
HandlebarsTemplates["operation_logs/_oplog_row"] = compiled;

import { single_oplog_row } from "templates/operation_logs/single_oplog_row";
var compiled = Handlebars.compile(single_oplog_row);
Handlebars.registerPartial("operation_logs/single_oplog_row", compiled);
HandlebarsTemplates["operation_logs/single_oplog_row"] = compiled;

import { _attachment } from "templates/common_elements/_attachment";
var compiled = Handlebars.compile(_attachment);
Handlebars.registerPartial("common_elements/_attachment", compiled);
HandlebarsTemplates["common_elements/_attachment"] = compiled;

import { _user_image } from "templates/common_elements/_user_image";
var compiled = Handlebars.compile(_user_image);
Handlebars.registerPartial("common_elements/_user_image", compiled);
HandlebarsTemplates["common_elements/_user_image"] = compiled;

import { _tag } from "templates/common_elements/_tag";
var compiled = Handlebars.compile(_tag);
Handlebars.registerPartial("common_elements/_tag", compiled);
HandlebarsTemplates["common_elements/_tag"] = compiled;

import { _comment } from "templates/common_elements/_comment";
var compiled = Handlebars.compile(_comment);
Handlebars.registerPartial("common_elements/_comment", compiled);
HandlebarsTemplates["common_elements/_comment"] = compiled;

import { _task_row } from "templates/tasks/_task_row";
var compiled = Handlebars.compile(_task_row);
Handlebars.registerPartial("tasks/_task_row", compiled);
HandlebarsTemplates["tasks/_task_row"] = compiled;

import { task_list } from "templates/tasks/task_list";
var compiled = Handlebars.compile(task_list);
Handlebars.registerPartial("tasks/task_list", compiled);
HandlebarsTemplates["tasks/task_list"] = compiled;

import { single_task_row } from "templates/tasks/single_task_row";
var compiled = Handlebars.compile(single_task_row);
Handlebars.registerPartial("tasks/single_task_row", compiled);
HandlebarsTemplates["tasks/single_task_row"] = compiled;

import { watches_list } from "templates/watches/watches_list";
var compiled = Handlebars.compile(watches_list);
Handlebars.registerPartial("watches/watches_list", compiled);
HandlebarsTemplates["watches/watches_list"] = compiled;

import { info } from "templates/tasks/info";
var compiled = Handlebars.compile(info);
Handlebars.registerPartial("tasks/info", compiled);
HandlebarsTemplates["tasks/info"] = compiled;

import { _attachment_row } from "templates/files/_attachment_row";
var compiled = Handlebars.compile(info);
Handlebars.registerPartial("files/_attachment_row", compiled);
HandlebarsTemplates["files/_attachment_row"] = compiled;

import { file_rows } from "templates/files/file_rows";
var compiled = Handlebars.compile(info);
Handlebars.registerPartial("files/file_rows", compiled);
HandlebarsTemplates["files/file_rows"] = compiled;

import { sharebuttons } from "templates/share/sharebuttons";
var compiled = Handlebars.compile(sharebuttons);
Handlebars.registerPartial("share/sharebuttons", compiled);
HandlebarsTemplates["share/sharebuttons"] = compiled;

import { _priority_row } from "templates/priorities/_priority_row";
var compiled = Handlebars.compile(_priority_row);
Handlebars.registerPartial("priorities/_priority_row", compiled);
HandlebarsTemplates["priorities/_priority_row"] = compiled;

import { priority_list } from "templates/priorities/priority_list";
var compiled = Handlebars.compile(priority_list);
Handlebars.registerPartial("priorities/priority_list", compiled);
HandlebarsTemplates["priorities/priority_list"] = compiled;

import { unaccepted_risk_checklist } from "templates/risks/unaccepted_risk_checklist";
var compiled = Handlebars.compile(unaccepted_risk_checklist);
Handlebars.registerPartial("risks/unaccepted_risk_checklist", compiled);
HandlebarsTemplates["risks/unaccepted_risk_checklist"] = compiled;
