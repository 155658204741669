export var _user_image = `
<div class="image-circle float-left text-center mt-2"
      style="
        {{#if user.include_picture}}
          background-image: url({{user.include_picture.small}});
        {{^}}
          background-color: {{theme_color}};
        {{/if}}
">
  {{#unless user.include_picture}}
    <h5 style="margin-top: 3px;" class="text-white nopadding">{{firstLetter user.name}}</h5>
  {{/unless}}
</div>
`;
