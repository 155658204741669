export var unaccepted_risk_checklist = `
<h1>{{i18n 'operation.risks.risks'}}</h1>
{{#risks}}
  <h3><strong>{{hazard}}</strong>: {{action}}</h3>
  <ul>
  {{#tasks}}
    {{#unless accepts_groups}}
      <li>
        {{name}}
       </li>
    {{/unless}}
  {{/tasks}}
  </ul>
{{/risks}}
<strong class="topmargin">{{i18n 'operation.risks.confirmation_text'}}</strong>

`
