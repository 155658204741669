export var marker_popup = `
<h4>{{name}} {{# if search_area_id }} [{{search_area.name}}] {{/if}}</h4>
{{#if search_area}}
  {{#ifEquals search_area.json.geoJSON.geometry.type "LineString"}}
    <span> <strong>{{i18n 'operation.task.length'}}:</strong> {{toFixed search_area.json.polyline_length 3}} km</span>
  {{^}}
    <span> <strong>{{i18n 'operation.task.size'}}:</strong> {{toFixed search_area.json.area_size 3}} km²</span>
  {{/ifEquals}}
{{/if}}
{{#if groups}}
  <br><strong>{{i18n 'operation.index.groups'}}:</strong>
  {{#each groups}}
    <br><span>{{name}} [{{tetra}}]</span>
  {{/each}}
{{/if}}
<div class="button-group topmargin">
    <button class='btn btn-primary edit_task_button' onclick="all_tasks_coordinates['{{id}}'].getEditForm()">{{i18n 'helpers.edit'}}</button>
  {{#if done}}
    <button class='btn btn-warning' onclick="all_tasks_coordinates['{{id}}'].updateStatus({done:false, active: false})">{{i18n 'operation.search_areas.reactivate'}}</button>
  {{^}}
    <button class='btn btn-success' onclick="all_tasks_coordinates['{{id}}'].updateStatus({done:true, active: true})">{{i18n 'operation.task.finish'}}</button>
  {{/if}}
</div>
`